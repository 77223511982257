import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { FC } from "react";
import styled from "styled-components";

const ContactConfirmation: FC = () => {
    return (
        <>
            <PageContainer>
                <PageTitle>Takk for din henvendelse</PageTitle>
                <PageText>
                    Takk for at du tok kontakt med oss. Din henvendelse er registrert og vil bli behandlet så raskt som
                    mulig.
                </PageText>
                <PageText>Du kan også kontakte oss via telefon og e-post.</PageText>
                <HomeButton to={`/`} title={`Hjem`} />
            </PageContainer>
        </>
    );
};

export default ContactConfirmation;

const PageContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh;

    max-width: 960px;
    padding: 5rem 3rem 1rem;
    margin: 0 auto;
`;

const PageTitle = styled.h2`
    font-size: var(--font-title2-size);
    margin: 2rem 0;
    text-align: center;
`;

const PageText = styled.p`
    font-size: var(--font-base-size);
    margin: 1rem 0;
    text-align: center;
`;

const HomeButton = styled(AnchorLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-large-size);
    margin: 2rem 0;
    background-color: var(--button-color);
    color: #fff;
    border: none;
    border-radius: 2px;
    height: 3rem;
    padding: 0 1rem;
    transition: var(--transition);

    &:hover {
        background-color: var(--button-color-hover);
        cursor: pointer;
    }
`;
