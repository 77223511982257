import { graphql } from "gatsby";
import React, { FC } from "react";
import ContactConfirmation from "../components/ContactConfirmation";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/SEO";
import Layout from "../containers/layout";

export const query = graphql`
    query ConfirmationPageQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }
    }
`;

const ContactConfirmationPage: FC<{ data: any; errors: any }> = (props) => {
    const { data, errors } = props;

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        );
    }

    const site = (data || {}).site;

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        );
    }

    return (
        <Layout>
            <Seo title="Kontaktskjema Bekreftelse" description={site.description} keywords={site.keywords} />
            <ContactConfirmation />
        </Layout>
    );
};

export default ContactConfirmationPage;
